import { AuthSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const { username, email } = useSelector(AuthSelectors.firebaseProfile)

  return (
    <div className="w-full h-full">
      <span id="noctua-retool-email" style={{ display: 'none' }}>
        {email}
      </span>
      <span id="noctua-retool-username" style={{ display: 'none' }}>
        {username}
      </span>

      <div className="p-10">
        <h3 className="mb-4">Welcome to Noctua</h3>
        <p>
          To get started, simply choose a workflow from the menu located on the
          left side of the page. If you don't have access to any page, please
          contact the admin for assistance.
          <br />
          Let’s get started!
        </p>
      </div>
    </div>
  )
}
